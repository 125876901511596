import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const WorkPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <main>
        <div className="image-heading">
          <StaticImage
            src="../images/work.png"
            width={4000}
            alt="workのheaderの画像"
          />
        </div>
        <div className="cntWrapper">
          <ol class="breadcrumb">
            <li>
              <a href="/">TOP</a>
            </li>
            <li>ホームページ制作実績</li>
          </ol>
          <div className="wrapper-card">
            <div className="card card-1">
              <Link href="https://niku-nagaoka.com/">
                <div className="card-wrapper">
                  <StaticImage
                    formats={["auto", "webp"]}
                    src="../images/nagaoka.png"
                    width={460}
                    height={270}
                    quality={95}
                    alt="top_aboutus"
                  />
                  <div className="card-title">
                    肉のながおか様
                    <div className="card-subtitle">ホームページ制作</div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="card card-2">
              <Link href="https://seishin-hari.com/">
                <div className="card-wrapper">
                  <StaticImage
                    formats={["auto", "webp"]}
                    src="../images/kamata.png"
                    width={460}
                    height={270}
                    quality={95}
                    alt="top_greeting"
                  />
                  <div className="card-title">
                    政鍼様<div className="card-subtitle">ホームページ制作</div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default WorkPage

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
  <Seo
    title="ホームページ制作実績"
    description="Jamstackホームページ制作屋のホームページ制作実績の一部をご紹介します。"
    cover={"/work.png"}
  />
)

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`
